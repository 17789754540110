import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = {
  key: 0,
  class: "container"
}
const _hoisted_3 = { class: "text-center p-3 alert alert-danger mt-6" }
const _hoisted_4 = {
  key: 1,
  class: "container"
}
const _hoisted_5 = { class: "text-center p-3 alert alert-primary mt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    ($setup.isWeb === true)
      ? (_openBlock(), _createBlock("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, _toDisplayString($setup.message), 1)
        ]))
      : (_openBlock(), _createBlock("div", _hoisted_4, [
          _createVNode("div", _hoisted_5, _toDisplayString($setup.message), 1)
        ]))
  ]))
}