import apiUrl from '../../api_url.global';
import {
	dbNum,
	addCommentName,
	tagIdx,
	suggestionDataIdx
} from '@/composition/store';
import {
	apiAdminPageTable,
	apiLanguage,
	apiAddComment,
	apiDeleteComment,
	apiEditComment,
	apiAddLanguage,
	apiDeleteLanguage,
	apiUpdateSuggestions,
	apiAddLanguageColumn,
	apiEditCommentName
} from '@/composition/api';

export const requestAdminTableFn = () => {
	const requestAdminTable = async () => {
		const config: any = {
			baseURL: `${apiUrl.url}${dbNum.value}`,
			url:
				'/prediction/comment/findall',
			headers: {
				'Content-Type':
					'application/json'
			},
			method: 'get'
		};
		apiAdminPageTable(config);
	};
	const editCommentName = async (
		val,
		val2
	) => {
		const config: any = {
			baseURL: `${apiUrl.url}${dbNum.value}`,
			url: `/prediction/comment/edit`,
			headers: {
				'Content-Type':
					'application/json'
			},
			method: 'post',
			data: {
				type: val,
				comment_id: val2
			}
		};
		console.log(config);
		apiEditCommentName(config);
	};
	const requestLanguageList = async () => {
		const config: any = {
			baseURL: `${apiUrl.url}${dbNum.value}`,
			url: '/config/find/Languages',
			headers: {
				'Content-Type':
					'application/json'
			},
			method: 'get'
		};
		apiLanguage(config);
	};
	const addLanguage = async (
		val,
		val2
	) => {
		val2.forEach((item1) => {
			const config1 = {
				baseURL: `${apiUrl.url}${dbNum.value}`,
				url: `/prediction/comment/edit`,
				headers: {
					'Content-Type':
						'application/json'
				},
				method: 'post',
				data: {
					type: '',
					comment_id: '',
					description: [
						{
							lang: val,
							text_content: ''
						}
					],
					suggestions: []
				}
			};
			// const data: any = {
			// 	type: "",
			// 	comment_id: "",
			// 	description: [
			// 		{
			// 			lang: val,
			// 			text_content: ""
			// 		}
			// 	],
			// 	suggestions: []
			// };
			config1.data.type = item1.type;
			config1.data.comment_id =
				item1.comment_id;
			item1.suggestions.forEach(
				(item2) => {
					config1.data.suggestions.push(
						{
							id: item2.id,
							text_contents: [
								{
									lang: val,
									text_content: ''
								}
							]
						}
					);
				}
			);
			console.log(config1);
			apiAddLanguageColumn(config1);
		});

		const config: any = {
			baseURL: `${apiUrl.url}${dbNum.value}`,
			url: `/config/language/add/${val}`,
			headers: {
				'Content-Type':
					'application/json'
			},
			method: 'put'
		};
		console.log(config);
		apiAddLanguage(config);
	};
	const deleteLanguage = async (
		val
	) => {
		const config: any = {
			baseURL: `${apiUrl.url}${dbNum.value}`,
			url: `/config/language/delete/${val}`,
			headers: {
				'Content-Type':
					'application/json'
			},
			method: 'delete'
		};
		console.log(config);
		apiDeleteLanguage(config);
	};

	const modalData = async (e) => {
		tagIdx.value = e;
	};
	const addAiComment = async (
		val1,
		val2
	) => {
		console.log(val1);
		console.log(val2);

		const config: any = {
			baseURL: `${apiUrl.url}${dbNum.value}`,
			url: '/prediction/comment/create',
			headers: {
				'Content-Type':
					'application/json'
			},
			method: 'post',
			data: {
				type: val1,
				description: [],
				suggestions: [
					{ text_contents: [] }
				]
			}
		};
		val2.forEach((item) => {
			config.data.description.push({
				lang: item,
				text_content: ''
			});
			config.data.suggestions[0].text_contents.push(
				{
					lang: item,
					text_content: ''
				}
			);
		});
		console.log(config);
		apiAddComment(config);
		addCommentName.value = '';
		// addCommentData.value.lang = "";
		// addCommentData.value.descriptions =
		// 	"";
		// addCommentData.value.suggestions =
		// 	"";
	};
	const deleteAiComment = async (e) => {
		console.log(e);
		const config: any = {
			baseURL: `${apiUrl.url}${dbNum.value}`,
			url: `/prediction/comment/delete/${e}`,
			headers: {
				'Content-Type':
					'application/json'
			},
			method: 'delete'
		};
		console.log(config);
		apiDeleteComment(config);
	};
	const editDescription = async (
		val,
		val2
	) => {
		console.log(val);
		console.log(val2);

		const config: any = {
			baseURL: `${apiUrl.url}${dbNum.value}`,
			url: `/prediction/comment/edit`,
			headers: {
				'Content-Type':
					'application/json'
			},
			method: 'post',
			data: {
				type: val.type,
				comment_id: val.comment_id,
				description: [
					{
						lang:
							val.description[val2]
								.lang,
						text_content:
							val.description[val2]
								.text_content
					}
				]
			}
		};
		console.log(config);
		apiEditComment(config);
	};

	const suggestionData = async (e) => {
		suggestionDataIdx.value = e;
	};
	const addSuggestion = async (
		val,
		val2
	) => {
		console.log(val);
		console.log(val2);

		const config: any = {
			baseURL: `${apiUrl.url}${dbNum.value}`,
			url: `/prediction/comment/edit`,
			headers: {
				'Content-Type':
					'application/json'
			},
			method: 'post',
			data: {
				type: val.type,
				comment_id: val.comment_id,
				suggestions: [
					{ text_contents: [] }
				]
			}
		};
		val2.forEach((item) => {
			config.data.suggestions[0].text_contents.push(
				{
					lang: item,
					text_content: ''
				}
			);
		});
		console.log(config);
		apiUpdateSuggestions(config);
	};
	const editSuggestion = async (
		val,
		val2,
		val3
	) => {
		console.log(
			val.type,
			val.comment_id
		);
		console.log(val2);
		const config: any = {
			baseURL: `${apiUrl.url}${dbNum.value}`,
			url: `/prediction/comment/edit`,
			headers: {
				'Content-Type':
					'application/json'
			},
			method: 'post',
			data: {
				type: val.type,
				comment_id: val.comment_id,
				suggestions: [
					{
						id: val2.id,
						text_contents: [
							{
								lang:
									val2.text_contents[
										val3
									].lang,
								text_content:
									val2.text_contents[
										val3
									].text_content
							}
						]
					}
				]
			}
		};
		console.log(config);
		apiUpdateSuggestions(config);
	};
	const deleteSuggestion = async (
		val,
		val2
	) => {
		console.log(val);
		console.log(val2);

		const config: any = {
			baseURL: `${apiUrl.url}${dbNum.value}`,
			url: `/prediction/comment/suggestion/delete`,
			headers: {
				'Content-Type':
					'application/json'
			},
			method: 'delete',
			data: {
				type: val.type,
				comment_id: val.comment_id,
				suggestion_id: val2
			}
		};
		console.log(config);
		apiUpdateSuggestions(config);
	};
	return {
		requestAdminTable,
		requestLanguageList,
		modalData,
		addAiComment,
		deleteAiComment,
		editDescription,
		addLanguage,
		deleteLanguage,
		editSuggestion,
		deleteSuggestion,
		suggestionData,
		addSuggestion,
		editCommentName
	};
};
