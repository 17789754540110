/* eslint-disable @typescript-eslint/camelcase */
import apiUrl from '../../api_url.global';
import {
	apiRequestDiagnoses,
	apiDataCounts,
	apiGetUserIdFromPhoneNumber,
	apiDeleteDiagnoses,
	apiSwitchSynthetic,
	apiRequestDisabled,
	apiIsEnableDiagnoses
} from '@/composition/api';
import {
	dbNum,
	token,
	countNumber,
	page,
	selectPhone,
	insertTagName,
	selectPhoneRemove
} from '@/composition/store';

export const requestDiagnosesFn = () => {
	const requestDiagnoses = async (
		page
	) => {
		const config: any = {
			baseURL: `${apiUrl.url}${dbNum.value}`,
			// url:
			// 	JSON.parse(
			// 		localStorage.getItem(
			// 			'userinfo'
			// 		)
			// 	).phone === '77311699'
			// 		? '/diagnoses/dashboard/admin'
			// 		: '/diagnoses/dashboard',
			// url: '/diagnoses/dashboard/admin',
			url:
				dbNum.value === 'v1'
					? '/diagnoses/dashboard/'
					: '/diagnoses/dashboard/admin',
			headers: {
				'Content-Type':
					'application/json',
				platform: 'web',
				Authorization:
					'Bearer ' +
					localStorage.getItem(
						'access_token'
					)
			},
			method: 'post',
			data:
				sessionStorage.getItem(
					'selectPhone'
				) === null
					? {
							start_number:
								page * 10 - 9,
							end_number: page * 10,
							...(await data())
					  }
					: {
							start_number:
								page * 10 - 9,
							end_number: page * 10,
							// ...JSON.parse(
							// 	sessionStorage.getItem(
							// 		"selectData"
							// 	)
							// )
							...(await data())
					  }
		};
		console.log(config);
		await apiRequestDiagnoses(config);
	};

	const dataCounts = async () => {
		const config: any = {
			baseURL: apiUrl.url + dbNum.value,
			url: '/diagnoses/count',
			headers: {
				Authorization:
					'Bearer ' +
					localStorage.getItem(
						'access_token'
					),
				'Content-Type':
					'application/json',
				platform: 'web'
			},
			method: 'post',
			data:
				sessionStorage.getItem(
					'selectPhone'
				) === null
					? {
							start_number:
								page.value.diagnoses *
									10 -
								9,
							end_number:
								page.value.diagnoses *
								10,
							...(await data())
					  }
					: {
							start_number:
								page.value.diagnoses *
									10 -
								9,
							end_number:
								page.value.diagnoses *
								10,
							// ...JSON.parse(
							// 	sessionStorage.getItem(
							// 		"selectData"
							// 	)
							// )
							...(await data())
					  }
		};
		apiDataCounts(config);
	};

	const searchPhoneData = async () => {
		sessionStorage.setItem(
			'selectPhone',
			selectPhone.value
		);
		page.value.diagnoses = 1;
		const config1 = {
			baseURL: `${apiUrl.url}${dbNum.value}`,
			// url:
			// 	JSON.parse(
			// 		localStorage.getItem(
			// 			'userinfo'
			// 		)
			// 	).phone === '77311699'
			// 		? '/diagnoses/dashboard/'
			// 		: '/diagnoses/dashboard/admin',
			// url: '/diagnoses/dashboard/admin',
			url:
				dbNum.value === 'v1'
					? '/diagnoses/dashboard/'
					: '/diagnoses/dashboard/admin',
			headers: {
				'Content-Type':
					'application/json',
				platform: 'web',
				Authorization:
					'Bearer ' +
					localStorage.getItem(
						'access_token'
					)
			},
			method: 'post',
			data: {
				start_number:
					page.value.diagnoses * 10 - 9,
				end_number:
					page.value.diagnoses * 10,
				...(await data())
			}
		};
		const config2 = {
			baseURL: apiUrl.url + dbNum.value,
			url: '/diagnoses/count',
			headers: {
				Authorization:
					'Bearer ' +
					localStorage.getItem(
						'access_token'
					),
				'Content-Type':
					'application/json',
				platform: 'web'
			},
			method: 'post',
			data: {
				start_number:
					page.value.diagnoses * 10 - 9,
				end_number:
					page.value.diagnoses * 10,
				...(await data())
			}
		};
		await apiRequestDiagnoses(config1);
		await apiDataCounts(config2);
	};

	const data = async () => {
		const filterPhoneData = {
			medical_id: '01',
			// start_date: '',
			search_tag: false,
			tag_value: '',
			search_uid: '',
			user_id: '',
			phone: '',
			role: '',
			measure_person:
				dbNum.value === 'v1'
					? JSON.parse(
							localStorage.getItem(
								'userinfo'
							)
					  ).username
					: ''
		};
		console.log(dbNum.value);
		if (
			selectPhone.value &&
			insertTagName.value
		) {
			const getData: any = await apiGetUserIdFromPhoneNumber(
				selectPhone.value
			);
			filterPhoneData.search_uid =
				getData.user_id;
			// filterPhoneData.phone =
			// 	getData.phone;
			// filterPhoneData.role = 'api';
			filterPhoneData.search_tag = true;
			filterPhoneData.tag_value =
				insertTagName.value;
		} else if (
			selectPhone.value &&
			!insertTagName.value
		) {
			const getData: any = await apiGetUserIdFromPhoneNumber(
				selectPhone.value
			);
			filterPhoneData.search_uid =
				getData.user_id;
			// filterPhoneData.phone =
			// 	getData.phone;
			// filterPhoneData.role = 'api';
			filterPhoneData.search_tag = false;
			filterPhoneData.tag_value =
				insertTagName.value;
		} else if (
			!selectPhone.value &&
			insertTagName.value
		) {
			// const getData: any = await apiGetUserIdFromPhoneNumber(
			// 	selectPhone.value
			// );
			// filterPhoneData.user_id =
			// 	getData.user_id;
			// filterPhoneData.phone =
			// 	getData.phone;
			// filterPhoneData.role = "api";
			// filterPhoneData.user_id = JSON.parse(
			// 	localStorage.getItem('userinfo')
			// ).user_id;
			// filterPhoneData.phone = JSON.parse(
			// 	localStorage.getItem('userinfo')
			// ).phone;
			// filterPhoneData.role = JSON.parse(
			// 	localStorage.getItem('userinfo')
			// ).role;
			filterPhoneData.search_tag = true;
			filterPhoneData.tag_value =
				insertTagName.value;
		} else {
			// filterPhoneData.user_id = JSON.parse(
			// 	localStorage.getItem('userinfo')
			// ).user_id;
			// filterPhoneData.phone = JSON.parse(
			// 	localStorage.getItem('userinfo')
			// ).phone;
			// filterPhoneData.role = JSON.parse(
			// 	localStorage.getItem('userinfo')
			// ).role;
		}
		sessionStorage.setItem(
			'selectData',
			JSON.stringify(filterPhoneData)
		);
		return filterPhoneData;
	};
	const deleteDiagnoses = async (
		id
	) => {
		console.log(id);
		const config = {
			baseURL: apiUrl.url + dbNum.value,
			url: `/diagnoses/${id}/remove`,
			headers: {
				'Content-Type':
					'application/json'
			},
			method: 'delete'
		};
		apiDeleteDiagnoses(config);
	};
	const switchSynthetic = (id, val) => {
		const config: any = {
			baseURL: `${apiUrl.url}${dbNum.value}`,
			url: `/diagnoses/${id}/${!val}`,
			headers: {
				'Content-Type':
					'application/json'
			},
			method: 'patch'
		};
		console.log(config);
		apiSwitchSynthetic(config);
	};
	const requestDisableDiagnoses = (
		page,
		phone
	) => {
		sessionStorage.setItem(
			'selectPhoneRemove',
			selectPhoneRemove.value
		);
		const config: any = {
			baseURL: `${apiUrl.url}${dbNum.value}`,
			url: `/diagnoses/dashboard/invalid`,
			headers: {
				'Content-Type':
					'application/json'
			},
			method: 'post',
			data: {
				user_id: JSON.parse(
					localStorage.getItem(
						'userinfo'
					)
				).user_id,
				start_number: page * 10 - 9,
				end_number: page * 10,
				search_phone: phone,
				search_uid: ''
			}
		};
		console.log(config);

		apiRequestDisabled(config);
	};
	const isEnableDiagnoses = (
		id,
		val
	) => {
		const config: any = {
			baseURL: `${apiUrl.url}${dbNum.value}`,
			url: `/diagnoses/${id}/enable/${val}`,
			headers: {
				'Content-Type':
					'application/json'
			},
			method: 'patch'
		};
		apiIsEnableDiagnoses(config);
	};
	return {
		requestDiagnoses,
		dbNum,
		dataCounts,
		countNumber,
		searchPhoneData,
		deleteDiagnoses,
		switchSynthetic,
		requestDisableDiagnoses,
		isEnableDiagnoses
	};
};
