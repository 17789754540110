/* eslint-disable @typescript-eslint/camelcase */
import apiUrl from '../../api_url.global';
import {
	apiRequestDiagnoses,
	apiDataCounts
} from '@/composition/api';
import {
	dbNum,
	page
} from '@/composition/store';

import { requestDiagnosesFn } from "./requestDiagnoses"


// 指定用户账号 25358830
export const isSpecialUser = () => {
	const userinfoStr = localStorage.getItem('userinfo')
	const userInfo = userinfoStr ? JSON.parse(userinfoStr) : null
	return userInfo?.phone === '25358830'
}
export const useRequestDiagnosesBySpeciaUser = () => {

	const device_ids = [
		"i800000135",
		"i800000138",
		"i800000146",
		"i800000149",
		"i800000150",
		"i800000151",
		"i800000159",
		"i800000164",
		"i800000216",
		"i800000189",
		"i800000214"]
	const requestDiagnoses = async (
		page
	) => {
		debugger
		const config: any = {
			baseURL: `${apiUrl.url}${dbNum.value}`,
			url: "/diagnoses/device_ids",
			headers: {
				'Content-Type':
					'application/json',
				platform: 'web',
				Authorization:
					'Bearer ' +
					localStorage.getItem(
						'access_token'
					)
			},
			method: 'post',
			data:
			{
				start_number:
					page * 10 - 9,
				end_number: page * 10,
				device_ids
			}
		};
		await apiRequestDiagnoses(config);
	};

	const dataCounts = async () => {
		const config: any = {
			baseURL: apiUrl.url + dbNum.value,
			url: '/diagnoses/device_ids/count',
			headers: {
				Authorization:
					'Bearer ' +
					localStorage.getItem(
						'access_token'
					),
				'Content-Type':
					'application/json',
				platform: 'web'
			},
			method: 'post',
			data: {
				start_number:
					page.value.diagnoses *
					10 -
					9,
				end_number:
					page.value.diagnoses *
					10,
				device_ids
			}
		};

		apiDataCounts(config);
	};
	const { countNumber, searchPhoneData, deleteDiagnoses, switchSynthetic, requestDisableDiagnoses, isEnableDiagnoses } = requestDiagnosesFn()
	return {
		requestDiagnoses,
		dbNum,
		dataCounts,
		countNumber,
		searchPhoneData,
		deleteDiagnoses,
		switchSynthetic,
		requestDisableDiagnoses,
		isEnableDiagnoses
	};
};
